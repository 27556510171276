<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <div class="container">
        <div
          class="mt-5 mb-10 pb-10 d-flex justify-content-left align-items-center"
        >
          <div>
            <img
              src="media/logos/logo-letter-9.png"
              class="max-h-120px img-fluid mr-10"
              alt=""
            />
          </div>
          <h1 class="brandlogoText">USVI Office of Veterans Affairs</h1>
        </div>
        <div
          class="d-flex row m-0 justify-content-between align-items-center h-75 pb-10 mb-10"
        >
          <!--begin::Content-->
          <div class="login-content col-md-6">
            <!--begin::Signin-->
            <div class="login-form login-signin">
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
              >
                <div class="pb-13 pt-lg-0 pt-5">
                  <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                  >
                    Sign In
                  </h3>
                </div>
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark"
                    >Email</label
                  >
                  <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                  >
                    <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg-0"
                      type="text"
                      name="email"
                      ref="email"
                      v-model="login_form.email"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between mt-n5">
                    <label
                      class="font-size-h6 font-weight-bolder text-dark pt-5"
                      >Password</label
                    >
                    <a
                      class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                      id="kt_login_forgot"
                      @click="showForm('forgot')"
                      >Forgot Password ?</a
                    >
                  </div>
                  <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                  >
                    <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg-0"
                      type="password"
                      name="password"
                      ref="password"
                      v-model="login_form.password"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <span class="text-error">{{ loginError }}</span>
                <div
                  class="pb-lg-0 pb-5 text-center"
                  style="display: flex; justify-content: space-between"
                >
                  <button
                    ref="kt_login_signin_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3 btnCommonVeterans"
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
            <!--end::Signin-->
            <!--begin::Signup-->
            <div class="login-form login-signup">
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signup_form"
              >
                <div class="pb-13 pt-lg-0 pt-5">
                  <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                  >
                    Sign Up
                  </h3>
                  <p class="text-muted font-weight-bold font-size-h4">
                    Enter your details to create your account
                  </p>
                </div>
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg-0 font-size-h6"
                    type="text"
                    placeholder="Fullname"
                    name="fullname"
                    ref="fullname"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg-0 font-size-h6"
                    type="email"
                    placeholder="Email"
                    name="email"
                    ref="remail"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg-0 font-size-h6"
                    type="password"
                    placeholder="Password"
                    name="password"
                    ref="rpassword"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg-0 font-size-h6"
                    type="password"
                    placeholder="Confirm password"
                    name="cpassword"
                    ref="cpassword"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group">
                  <label class="checkbox mb-0">
                    <input type="checkbox" name="agree" />
                    <span class="mr-2"></span>
                    I Agree the
                    <a href="#" class="ml-2">terms and conditions</a>.
                  </label>
                </div>
                <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                  <button
                    ref="kt_login_signup_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                    style="width: 150px"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    id="kt_login_signup_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
            <!--end::Signup-->
            <!--begin::Forgot-->
            <div class="login-form login-forgot">
              <!--begin::Form-->
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_forgot_form"
                ref="kt_login_forgot_form"
              >
                <div class="pb-13 pt-lg-0 pt-5">
                  <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                  >
                    Forgotten Password ?
                  </h3>
                  <p class="text-muted font-weight-bold font-size-h4">
                    Enter your email to reset your password
                  </p>
                </div>
                <div class="form-group">
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg-0 font-size-h6"
                    type="email"
                    placeholder="Email"
                    name="email"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group d-flex flex-wrap pb-lg-0">
                  <button
                    type="button"
                    id="kt_login_forgot_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
            <!--end::Forgot-->
          </div>
          <!--end::Content-->
          <!--begin::Aside-->
          <div class="d-flex flex-column-auto flex-column col-md-6">
            <div class="mb-10 mt-10">
              <div class="row text-center justify-content-center">
                <div class="col-4">
                  <img
                    src="media/department-logo/army.png"
                    class="max-h-120px img-fluid mt-6 mb-6"
                    alt=""
                  />
                </div>
                <div class="col-4">
                  <img
                    src="media/department-logo/states-marine-corps.png"
                    class="max-h-120px img-fluid mt-6 mb-6"
                    alt=""
                  />
                </div>
                <div class="col-4">
                  <img
                    src="media/department-logo/navy.png"
                    class="max-h-120px img-fluid mt-6 mb-6"
                    alt=""
                  />
                </div>
                <div class="col-4">
                  <img
                    src="media/department-logo/seal.png"
                    class="max-h-120px img-fluid mt-6 mb-6"
                    alt=""
                  />
                </div>
                <div class="col-4">
                  <img
                    src="media/department-logo/coast-guard.png"
                    class="max-h-120px img-fluid mt-6 mb-6"
                    alt=""
                  />
                </div>
                <div class="col-4">
                  <img
                    src="media/department-logo/air-force.png"
                    class="max-h-120px img-fluid mt-6 mb-6"
                    alt=""
                  />
                </div>
                <div class="col-4">
                  <img
                    src="media/department-logo/public-health-service.png"
                    class="max-h-120px img-fluid mt-6 mb-6"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <!--begin::Aside-->
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
// import axios from "axios";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      login_form: {
        email: "",
        password: "",
      },
      loginError: "",
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same",
            },
          },
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      var email = this.login_form.email;
      var password = this.login_form.password;

      // clear existing errors
      // this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(LOGIN, { USERNAME: email, PASSWORD: password })
        // go to which page after successfully login
        .then(() => this.$router.push({ name: "dashboard" }))
        .catch((error) => {
          this.loginError = error;
          // this.error.message = 'THis is a text'
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            email: email,
            password: password,
          })
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
<style scoped>
.form-control {
  border-radius: 0 !important;
}
.brandlogoText {
  border-left: solid 1px #fff;
  padding-left: 20px;
  font-size: 60px;
  font-family: "Varela Round", "Helvetica Neue", "Helvetica", "Roboto", "Arial",
    sans-serif;
  color: #fff;
  /*height: 60px;*/
  padding-bottom: 0;
  margin-bottom: 0;
  text-transform: uppercase;
}
.max-h-120px {
  max-height: 150px;
}
.h-75 {
  min-height: 75vh !important;
}
.text-error {
  color: red;
}
</style>
